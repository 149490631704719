import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule} from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LoaderModule } from './_services/loader/loader.module';
import { LoaderService } from './_services/loader/loader.service';
import { WindowRef } from './_services/windowref.service';
import { ServerConn, serverConnCreator } from './_services/serverConn.service';
import { MqIfDirectiveModule } from './_directives/mqif.directive';

import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { getAuthServiceConfigs } from './socialLoginConfig';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
// import { MainComponent } from './main/main.component';
import { MainMenuModule } from './_components/main-menu/main-menu.module';

@NgModule({
    declarations: [
        AppComponent,
        // MainComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        HttpClientModule,
        // FormsModule,
        // ReactiveFormsModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        FlexLayoutModule,
        LoaderModule,
        MqIfDirectiveModule,
        SocialLoginModule,
        AppRoutingModule,
        MainMenuModule,
    ],
    providers: [
        {
            provide: ServerConn,
            useFactory: serverConnCreator,
            deps: [
                HttpClient,
                Router,
                LoaderService,
                WindowRef
            ]
        },
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs,
        },
        AuthGuard,
        AuthenticationService,
        WindowRef,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
