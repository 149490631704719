import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import decode from 'jwt-decode';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

    userData: any;

    constructor(
        private router: Router,
        public auth: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const api_key = localStorage.getItem('api_key');
        let userData: any;

        return true;

        if (api_key > '') {
            console.log (api_key);
            try {
                userData = decode(api_key);
            } catch {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
            this.auth.user = userData;
            // console.log(userData);
            if (typeof userData.user !== 'undefined') {
                return true;
            } else {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        } else {
            console.log('not api_key??');
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }

        return false;

    }

}
