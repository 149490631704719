import { Injectable } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import { Observable, of } from 'rxjs';

import { ServerConn } from './serverConn.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    appTitle = 'CIC - Tasks Viewer';
    shortTitle = 'TV';
    appSubTitle = '';
    // comunidades = [];

    constructor(
        private server: ServerConn
    ) {
        // this.server.get(
        //     'sure/data/comunidades'
        // ).subscribe(res => {
        //     if (res.result) {
        //         this.comunidades = res.data;
        //     }
        // });
    }

}
