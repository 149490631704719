import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderState } from './loader';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();

    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show() {
        /**
         * We use setTimeOut to avoid the "ExpressionChangedAfterItHasBeenCheckedError" error
         */
        setTimeout(() => {
            this.loaderSubject.next(<LoaderState>{show: true});
        });
    }

    hide() {
        setTimeout(() => {
            this.loaderSubject.next(<LoaderState>{show: false});
        });
    }
}