import { Component, Input, OnInit } from '@angular/core';

export interface MenuOptions {
    shortTitle?: string;
    menu: MenuItem[];
}

export interface MenuItem {
    path: string;
    label: string;
    icon: string;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

    componentId = 'mainmnu';
    sideNavOpened = false;
    shortTitle = '';
    @Input()
    options: MenuOptions;

    constructor(
    ) {
    }

    ngOnInit() {
        if (this.options.shortTitle) {
            this.shortTitle = this.options.shortTitle;
        }
    }

    public toggleSideNav(): void {

        this.sideNavOpened = !this.sideNavOpened;
        localStorage.setItem('sideNavOpened', this.sideNavOpened ? 'true' : '');

    }

    closeSideNav(): void {

        this.sideNavOpened = false;
        localStorage.setItem('sideNavOpened', '');

    }

}
