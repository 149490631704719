import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MqIfDirectiveModule } from '../../_directives/mqif.directive';

import { MainMenuComponent } from './main-menu.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatSlideToggleModule,
        FlexLayoutModule,
        MqIfDirectiveModule,
    ],
    declarations: [
        MainMenuComponent
    ],
    exports: [
        MainMenuComponent
    ],
    entryComponents: [
        MainMenuComponent
    ]
})
export class MainMenuModule { }
