import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';

import { AppService } from './_services/app.service';
import { SignService } from './_services/sign.service';
import { MainMenuComponent } from './_components/main-menu/main-menu.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    @ViewChild('mainMenu', { static: true })
    mainMenu: MainMenuComponent;

    user = {
        imageUrl: '',
    };

    title = 'AZASA - SURE';
    sideNavOpened = false;
    logged = false;
    isOpened = false;
    menuOptions = {
        shortTitle: 'TV',
        menu: [
            {
                path: '/main',
                label: 'Inicio',
                icon: 'home'
            },
            {
                path: '/calidad/tareas',
                label: 'Tareas',
                icon: 'list'
            },
            {
                path: '/usuarios',
                label: 'Usuarios',
                icon: 'people'
            },
        ]
    };

    constructor(
        public appSvc: AppService,
        private signSvc: SignService,
    ) {
    }

    ngOnInit() {
        const imageUrl = localStorage.getItem('imageUrl');
        if (imageUrl) {
            this.user.imageUrl = imageUrl;
        }
    }

    checkIfLogged() {
        const api_key = localStorage.getItem('api_key');
        if (api_key) {
            this.signSvc.signinCheck().subscribe(res => {
                console.log(res);
                if (res.result) {
                    this.logged = true;
                    this.isOpened = false;
                } else {
                    this.logged = false;
                    this.isOpened = false;
                }
            });
        }
    }

    toggleSideNav(): void {

        // console.log(this.mainMenu);

        this.mainMenu.toggleSideNav();
        // this.sideNavOpened = !this.sideNavOpened;
        // localStorage.setItem('sideNavOpened', this.sideNavOpened ? 'true' : '');

    }

    logout(): void {
        localStorage.setItem('api_key', 'false');
        this.checkIfLogged();
    }

}
