import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css']
})
export class LoaderComponent implements OnInit, AfterViewInit, OnDestroy {

    show = false;

    private subscription: Subscription;

    constructor(
        private loaderService: LoaderService,
        private cd: ChangeDetectorRef
    ) {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
        });
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        /**
         * To avoid the "RequestChangeError"...
         */
        this.cd.detectChanges();
    }

    canShow() {
        return this.show;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
