import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import decode from 'jwt-decode';

import { ServerConn, APIResponse } from './serverConn.service';

interface SignInParams {
    user: string;
    password: string;
    api_key?: string;
}

interface SignUpParams {
    nombreComercial: string;
    password: string;
    confirmPassword: string;
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class SignService {

    user: any;

    constructor(
        private server: ServerConn
    ) {
    }

    signin(params: SignInParams): Observable<APIResponse> {
        if (typeof params.api_key === 'undefined') {
            params.api_key = 'none';
        }
        return Observable.create((observer: Observer<APIResponse>) => {
            this.server.get(
                'signin',
                params
            ).subscribe((res: APIResponse) => {
                console.log(res);
                let user: any;
                if (!res.result) {
                    observer.next(res);
                }
                try {
                    user = decode(res.data.api_key);
                } catch {
                    console.log('Error al descodificar el token');
                }
                this.user = user;
                if (user && typeof user.id !== 'undefined' && typeof user.user !== 'undefined') {
                    localStorage.setItem('api_key', res.data.api_key);
                    localStorage.setItem('sssn', res.data.sssn);
                    res.data.user = user;
                }
                observer.next(res);
            },
            (err: any) => {
                observer.error(err);
            });
        });
    }

    getUser() {

        let user: any = false;

        try {
            user = decode(localStorage.getItem('api_key'));
        } catch {
            console.log('Error al descodificar el token');
        }

        return user;

    }

    signinCheck(): Observable<APIResponse> {
        return Observable.create((observer: Observer<APIResponse>) => {
            this.server.get(
                'signin/check'
            ).subscribe((res: APIResponse) => {
                let user: any;
                if (!res.result) {
                    observer.next(res);
                }
                try {
                    user = decode(res.data.api_key);
                } catch {
                    console.log('Error al descodificar el token');
                }
                this.user = user;
                if (user && typeof user.id !== 'undefined' && typeof user.user !== 'undefined') {
                    localStorage.setItem('api_key', res.data.api_key);
                    localStorage.setItem('sssn', res.data.sssn);
                    res.data.user = user;
                }
                observer.next(res);
            },
            (err: any) => {
                observer.error(err);
            });
        });
    }

    signup(params: SignUpParams): Observable<APIResponse> {
        return Observable.create((observer: Observer<APIResponse>) => {
                this.server.post(
                'sure/signup',
                params
            ).subscribe((res: APIResponse) => {
                console.log(res);
                observer.next(res);
            },
            (err: any) => {
                observer.error(err);
            });
        });
    }

}
