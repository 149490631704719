import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';
import { RoleGuardService as RoleGuard } from './_guards/role-guard.service';

const routes: Routes = [
    { path: '', redirectTo: '/main', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'main',  loadChildren: () => import('./main/main.module').then(m => m.MainModule), canActivate: [AuthGuard] },
    { path: 'clientes',  loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule), canActivate: [AuthGuard] },
    {
        path: 'calidad/tareas',
        loadChildren: () => import('./calidad-tasks/calidad-tasks.module').then(m => m.CalidadTasksModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'calidad/tareas/:idproject',
        loadChildren: () => import('./calidad-tasks/calidad-tasks.module').then(m => m.CalidadTasksModule),
        canActivate: [AuthGuard]
    },
    { path: 'usuarios',  loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule), canActivate: [AuthGuard] },
    // {
    //     path: 'lactaciones',
    //     loadChildren: 'app/hist-lactaciones/hist-lactaciones.module#HistLactacionesModule',
    //     canActivate: [RoleGuard],
    //     data: {
    //         expectedRole: 6,
    //         expectedModule: 4
    //     }
    // },
    { path: 'login',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'login/recover',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: []
})

export class AppRoutingModule {}
