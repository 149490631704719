import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { LoaderService } from './loader/loader.service';
import { WindowRef } from './windowref.service';

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

export interface APIResponse {
    result: boolean;
    data: any;
}

export function serverConnCreator(
        http: HttpClient,
        router: Router,
        loaderService: LoaderService,
        windowRef: WindowRef
    ) {
    return new ServerConn(http, router, loaderService, windowRef);
}

@Injectable()
export class ServerConn {

    public serverUrl = window.location.protocol + '//api.cic-systems.com/v1/';
    // public serverUrl = window.location.protocol + '//api.arana.ganaderos.sira.es/v1/';
    // public serverUrl = 'https://api.arana.ganaderos.sira.es/v1/';
    // public serverUrl = 'http://' +  + '/v1/';

    constructor(
        private http: HttpClient,
        private router: Router,
        private loaderService: LoaderService,
        private windowRef: WindowRef
    ) {
        // if (this.windowRef.nativeWindow.location.hostname.substr(0, 4) === 'app.') {
        //     this.serverUrl = windowRef.nativeWindow.location.protocol + '//' +
        //                         this.windowRef.nativeWindow.location.hostname.replace(/^app\./, 'api.') + '/v1/';
        // } else if (this.windowRef.nativeWindow.location.hostname !== 'localhost') {
        //     this.serverUrl = windowRef.nativeWindow.location.protocol +
        //                         '//api.' + this.windowRef.nativeWindow.location.hostname + '/v1/';
        // }
    }

    private static isJson (value: any): boolean {
        return /\bapplication\/json\b/.test(value.headers.get('Content-Type'));
    }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    }

    private onEnd(): void {
        this.hideLoader();
    }

    get(action: string | Request, params?: any) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const options = { headers: headers};
        const paramsObj = new URLSearchParams();
        let key: string;

        if (typeof params === 'undefined') {
            params = {};
        }

          // params.a = action;
        params.api_key = localStorage.getItem('api_key');
        params.sure = '1';

        // if (!params.api_key) {
        //     this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        //     return Observable.create();
        //         // exit;
        // }

      // if (this.isJson(params)) {
        for (key in params) {
            if (typeof params[key] !== 'undefined') {
                paramsObj.set(key, params[key]);
            }
        }

        this.showLoader();

        return Observable.create((observer) => {
            this.http.get(this.serverUrl + action + '?' + paramsObj.toString(), options).subscribe(
                res => observer.next(res), // simply passing success response
                err => { // error handling
                    console.log('global error handler', err);
                    this.checkError(err);
                    this.onEnd();
                    observer.error(err); // passing error to the method which invoked request
                },
                () => {
                  this.onEnd();
                  observer.complete();
                } // passing onComplete event);
            );
        });
    }

    checkError(err) {
        if (err.statusText === 'Unauthorized' || err.status === 401) {
            localStorage.removeItem('api_key');
            localStorage.removeItem('user');
            console.log(this.router.url);
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        }
    }

    post(action: string | Request, params?: any) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const options = { headers: headers};
        const paramsObj = new URLSearchParams();
        let key;

        if (typeof params === 'undefined') {
            params = {};
        }

          // params.a = action;
        params.api_key = localStorage.getItem('api_key');
        params.sure = '1';

      // if (this.isJson(params)) {
        for (key in params) {
            if (typeof params[key] !== 'undefined') {
                // paramsObj.set(key, params[key]);
                paramsObj.set(key, params[key]);
            }
        }

        this.showLoader();

        return Observable.create(observer => {
            this.http.post(this.serverUrl + action, paramsObj.toString(), options).subscribe(
                res => observer.next(res), // simply passing success response
                err => { // error handling
                       console.log('global error handler');
                       this.checkError(err);
                       this.onEnd();
                       observer.error(err); // passing error to the method which invoked request
                },
                () => {
                    this.onEnd();
                    observer.complete();
                } // passing onComplete event);
            );
        });
    }

    put(action: string | Request, params?: any) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const options = { headers: headers};
        const paramsObj = new URLSearchParams();
        let key;

        if (typeof params === 'undefined') {
            params = {};
        }

          // params.a = action;
        params.api_key = localStorage.getItem('api_key');
        params.sure = '1';

      // if (this.isJson(params)) {
        for (key in params) {
            if (typeof params[key] !== 'undefined') {
                // paramsObj.set(key, params[key]);
                paramsObj.set(key, params[key]);
            }
        }

        this.showLoader();

        return Observable.create(observer => {
            this.http.put(this.serverUrl + action, paramsObj.toString(), options).subscribe(
                res => observer.next(res), // simply passing success response
                err => { // error handling
                       console.log('global error handler');
                       this.checkError(err);
                       this.onEnd();
                       observer.error(err); // passing error to the method which invoked request
                },
                () => {
                    this.onEnd();
                    observer.complete();
                } // passing onComplete event);
            );
        });
    }

    delete(action: string | Request, params?: any) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const options = { headers: headers};
        const paramsObj = new URLSearchParams();
        let key;

        if (typeof params === 'undefined') {
            params = {};
        }

        params.api_key = localStorage.getItem('api_key');
        params.sure = '1';

        for (key in params) {
            if (typeof params[key] !== 'undefined') {
                paramsObj.set(key, params[key]);
            }
        }

        this.showLoader();

        return Observable.create(observer => {
            this.http.delete(this.serverUrl + action  + '?' + paramsObj.toString(), options).subscribe(
                res => observer.next(res), // simply passing success response
                err => { // error handling
                       console.log('global error handler');
                       this.checkError(err);
                       this.onEnd();
                       observer.error(err); // passing error to the method which invoked request
                },
                () => {
                    this.onEnd();
                    observer.complete();
                } // passing onComplete event);
            );
        });
    }

    download(action: string | Request, params: any = {}) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const options = { headers: headers, responseType: 'blob' as 'json', observe: 'response' as 'body'};
        const paramsObj = new URLSearchParams();
        let key: string;

          // params.a = action;
        params.api_key = localStorage.getItem('api_key');
        params.sure = '1';

        // if (!params.api_key) {
        //     this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        //     return Observable.create();
        // }

      // if (this.isJson(params)) {
        for (key in params) {
            if (typeof params[key] !== 'undefined') {
                paramsObj.set(key, params[key]);
            }
        }

        this.showLoader();

        return Observable.create(observer => {
            this.http.get(this.serverUrl + action + '?' + paramsObj.toString(), options).subscribe(
                res => observer.next(res), // simply passing success response
                err => { // error handling
                       console.log('global error handler', err);
                       this.checkError(err);
                       this.onEnd();
                       observer.error(err); // passing error to the method which invoked request
                },
                () => {
                  this.onEnd();
                  observer.complete();
                } // passing onComplete event);
            );
        });
    }

}
