import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';

export function getAuthServiceConfigs() {
    const config = new AuthServiceConfig([{
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('1092567402579-ia9jirte37u02j5tnp7jbqquqb1c4r35.apps.googleusercontent.com')
    }]);

    return config;
}
