import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as moment from 'moment';

import { ServerConn } from './serverConn.service';

export interface AccountData {
    card_data: string;
    cif: string;
    cpostal: string;
    direccion: string;
    email: string;
    explotaciones: any[];
    id: string;
    idcooperativa: string;
    idservidor: string;
    idsocio: string;
    movil: string;
    nombre: string;
    payments: any[];
    payments_id: string;
    poblacion: string;
    products: any[];
    provincia: string;
    server: string;
    stripe_customer: string;
    tfno: string;
}

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    data: AccountData;
    products: any[] = [];
    length = 0;

    constructor(
        private server: ServerConn
    ) {}

    get(force?): Observable<any> {

        if (!this.data || force) {
            return Observable.create(observer => {
                this.server.get(
                    'account'
                ).subscribe(
                    res => {
                        if (!this.products.length) {
                            this.getProducts();
                        }
                        const data = res.data;
                        this.data = data;
                        observer.next(res);
                    }, // simply passing success response
                    err => { // error handling
                           observer.error(err); // passing error to the method which invoked request
                    },
                    () => observer.complete // passing onComplete event);
                );
            });
            // return data;
        } else {
            return of(this.data);
        }
    }

    hasModule(module) {
        return this.products &&
               this.products.filter(prod => prod.id === module).length > 0;
    }

    getProducts(force?): Observable<any> {

        if (!this.products.length || force) {
            return Observable.create(observer => {
                this.server.get(
                    'account/products'
                ).subscribe(
                    res => {
                        const data = res.data;
                        console.log(res);
                        this.products = data;
                        observer.next(res);
                    }, // simply passing success response
                    err => { // error handling
                           observer.error(err); // passing error to the method which invoked request
                    },
                    () => observer.complete // passing onComplete event);
                );
            });
            // return data;
        } else {
            return of(this.data);
        }
    }

}
