import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { LoaderComponent } from './loader.component';
// import { LoaderService } from './loader.service';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
    ],
    declarations: [
        LoaderComponent,
        // LoaderService,
    ],
    exports: [
        LoaderComponent,
        // LoaderService,
    ],
    entryComponents: [
        LoaderComponent,
        // LoaderService,
    ]
})
export class LoaderModule {}
