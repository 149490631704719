import { Injectable } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import decode from 'jwt-decode';

import { ServerConn } from './serverConn.service';
import { AccountService } from './account.service';

export interface AuthResultType {
    result: boolean;
    data: {
        user?: any;
        api_key: string;
        sssn?: string;
    };
}

@Injectable()
export class AuthenticationService {
    authenticated = false;
    user = {};

    constructor(
        private serverConn: ServerConn,
        private router: Router,
        public accountSvc: AccountService,
    ) {}

    login(username: string, password: string) {

        return this.serverConn.get(
          'signin',
          {
            user: username,
            password: password,
            pwdenc: true
          }
        // ).map(response => response.json()).subscribe(response => {
        ).pipe(
            map(res => {
                const response: AuthResultType = <AuthResultType>res;
                let user;
                console.log(response);
                if (response.result) {
                    this.authenticated = true;
                    try {
                        user = decode(response.data.api_key);
                    } catch {
                        console.log('Error al descodificar el token');
                    }
                    console.log(user);
                    this.user = user;
                    if (user && typeof user.id !== 'undefined' && typeof user.user !== 'undefined') {
                        localStorage.setItem('api_key', response.data.api_key);
                        localStorage.setItem('sssn', response.data.sssn);
                    }
                    console.log(response);
                    // this.accountSvc.get().subscribe((resAccount) => {
                    //     console.log(resAccount);
                    // });
                }
                return res;
            })
        );

        // return this.http.post('/api/authenticate', JSON.stringify({ username: username, password: password }))
        //     .map((response: Response) => {
        //         // login successful if there's a jwt token in the response
        //         let user = response.json();
        //         if (user && user.token) {
        //             // store user details and jwt token in local storage to keep user logged in between page refreshes
        //             localStorage.setItem('currentUser', JSON.stringify(user));
        //         }
        //
        //         return user;
        //     });
    }

    loginWithgoogle(token: string) {

        return this.serverConn.post(
          'signin/google',
          {
            token: token
          }
        // ).map(response => response.json()).subscribe(response => {
        ).pipe(
            map(res => {
                const response: AuthResultType = <AuthResultType>res;
                let user;
                console.log(response);
                if (response.result) {
                    this.authenticated = true;
                    try {
                        user = decode(response.data.api_key);
                    } catch {
                        console.log('Error al descodificar el token');
                    }
                    console.log(user);
                    this.user = user;
                    if (user && typeof user.id !== 'undefined' && typeof user.user !== 'undefined') {
                        localStorage.setItem('api_key', response.data.api_key);
                        localStorage.setItem('sssn', response.data.sssn);
                    }
                    console.log(response);
                    // this.accountSvc.get().subscribe((resAccount) => {
                    //     console.log(resAccount);
                    // });
                }
                return res;
            })
        );

        // return this.http.post('/api/authenticate', JSON.stringify({ username: username, password: password }))
        //     .map((response: Response) => {
        //         // login successful if there's a jwt token in the response
        //         let user = response.json();
        //         if (user && user.token) {
        //             // store user details and jwt token in local storage to keep user logged in between page refreshes
        //             localStorage.setItem('currentUser', JSON.stringify(user));
        //         }
        //
        //         return user;
        //     });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }
}
